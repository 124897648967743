import React from 'react';
import 'yet-another-react-lightbox/styles.css';
// import slides from '../data/test1';
// import slides from '../data/test';
import {
  largeSlides15Evoque,
  largeSlides17GCTrailhawk,
  largeSlides17Nx300h,
  largeSlides18Escape,
  largeSlides18MUX,
  largeSlides20_mitsu_eclipse_cross,
  largeSlides21_dmax_singe_cab_4x2,
  largeSlides21_GLB200,
  largeSlides22_m3_sedan,
  largeSlides22_mazda_cx5_diesel_active,
  largeSlides22_tucson,
  smallSlides15Evoque,
  smallSlides17GCTrailhawk,
  smallSlides17Nx300h,
  smallSlides18Escape,
  smallSlides18MUX,
  smallSlides20_mitsu_eclipse_cross,
  smallSlides21_dmax_singe_cab_4x2,
  smallSlides21_GLB200,
  smallSlides22_m3_sedan,
  smallSlides22_mazda_cx5_diesel_active,
  smallSlides22_tucson,
} from '../data/stock';
import { CarAlbum } from './CarAlbum';

export const Stock = (props) => {
  return (
    <div>
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Stock</h2>
            <p>
              Our car stock offers a curated selection of top-quality vehicles, spanning various models and makes to suit every preference and budget.
            </p>
            <CarAlbum title="15 evoque" smallImages={smallSlides15Evoque} largeImages={largeSlides15Evoque} />
            <CarAlbum title="17 GC Trailhawk" smallImages={smallSlides17GCTrailhawk} largeImages={largeSlides17GCTrailhawk} />
            <CarAlbum title="17 nx300h" smallImages={smallSlides17Nx300h} largeImages={largeSlides17Nx300h} />
            <CarAlbum title="18 escape" smallImages={smallSlides18Escape} largeImages={largeSlides18Escape} />
            <CarAlbum title="18 MUX" smallImages={smallSlides18MUX} largeImages={largeSlides18MUX} />
            <CarAlbum
              title="20 mitsu eclipse cross"
              smallImages={smallSlides20_mitsu_eclipse_cross}
              largeImages={largeSlides20_mitsu_eclipse_cross}
            />
            <CarAlbum title="21 dmax singe cab 4x2" smallImages={smallSlides21_dmax_singe_cab_4x2} largeImages={largeSlides21_dmax_singe_cab_4x2} />
            <CarAlbum title="21 GLB200" smallImages={smallSlides21_GLB200} largeImages={largeSlides21_GLB200} />
            <CarAlbum title="22 m3 sedan" smallImages={smallSlides22_m3_sedan} largeImages={largeSlides22_m3_sedan} />
            <CarAlbum
              title="22 mazda cx5 diesel active"
              smallImages={smallSlides22_mazda_cx5_diesel_active}
              largeImages={largeSlides22_mazda_cx5_diesel_active}
            />
            <CarAlbum title="22 tucson" smallImages={smallSlides22_tucson} largeImages={largeSlides22_tucson} />
          </div>
        </div>
      </div>
    </div>
  );
};
