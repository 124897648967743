const WIDTH = 1706;
const HEIGHT = 1280;

// const getCarImage = (folderName, size, id) => `https://mhautos.com.au/img/stock/${folderName}/${size}/${id}`;
const getCarImage = (folderName, size, id) => require(`../img/cars/${folderName}/${size}/${id}`);

const getSlides = (carData, size) =>
  generateImageNames(carData.count).map((name) => ({ src: getCarImage(carData.folderName, size, name), width: WIDTH, height: HEIGHT }));

// generateImageNames(35) => ['01.jpp', '02.jpg'..., '35.jpg']
const generateImageNames = (count) => {
  const imageNames = [];
  for (let i = 1; i <= count; i++) {
    const imageName = i.toString().padStart(2, '0') + '.jpg';
    imageNames.push(imageName);
  }

  return imageNames;
};

// 15 evoque
const car_15_evoque = {
  folderName: '15_evoque',
  count: 25,
};

export const smallSlides15Evoque = getSlides(car_15_evoque, 'small');
export const largeSlides15Evoque = getSlides(car_15_evoque, 'large');

// 17_GC_Trailhawk
const car_17_GC_Trailhawk = {
  folderName: '17_GC_Trailhawk',
  count: 37,
};

export const smallSlides17GCTrailhawk = getSlides(car_17_GC_Trailhawk, 'small');
export const largeSlides17GCTrailhawk = getSlides(car_17_GC_Trailhawk, 'large');

// 17_nx300h
const car_17_nx300h = {
  folderName: '17_nx300h',
  count: 31,
};

export const smallSlides17Nx300h = getSlides(car_17_nx300h, 'small');
export const largeSlides17Nx300h = getSlides(car_17_nx300h, 'large');

// 18_escape
const car_18_escape = {
  folderName: '18_escape',
  count: 28,
};

export const smallSlides18Escape = getSlides(car_18_escape, 'small');
export const largeSlides18Escape = getSlides(car_18_escape, 'large');

// 18_MUX
const car_18_MUX = {
  folderName: '18_MUX',
  count: 28,
};

export const smallSlides18MUX = getSlides(car_18_MUX, 'small');
export const largeSlides18MUX = getSlides(car_18_MUX, 'large');

// 20_mitsu_eclipse_cross
const car_20_mitsu_eclipse_cross = {
  folderName: '20_mitsu_eclipse_cross',
  count: 27,
};

export const smallSlides20_mitsu_eclipse_cross = getSlides(car_20_mitsu_eclipse_cross, 'small');
export const largeSlides20_mitsu_eclipse_cross = getSlides(car_20_mitsu_eclipse_cross, 'large');

// 21_dmax_singe_cab_4x2
const car_21_dmax_singe_cab_4x2 = {
  folderName: '21_dmax_singe_cab_4x2',
  count: 34,
};

export const smallSlides21_dmax_singe_cab_4x2 = getSlides(car_21_dmax_singe_cab_4x2, 'small');
export const largeSlides21_dmax_singe_cab_4x2 = getSlides(car_21_dmax_singe_cab_4x2, 'large');

// 21_GLB200
const car_21_GLB200 = {
  folderName: '21_GLB200',
  count: 43,
};

export const smallSlides21_GLB200 = getSlides(car_21_GLB200, 'small');
export const largeSlides21_GLB200 = getSlides(car_21_GLB200, 'large');

// 22_m3_sedan
const car_22_m3_sedan = {
  folderName: '22_m3_sedan',
  count: 29,
};

export const smallSlides22_m3_sedan = getSlides(car_22_m3_sedan, 'small');
export const largeSlides22_m3_sedan = getSlides(car_22_m3_sedan, 'large');

// 22_mazda_cx5_diesel_active
const car_22_mazda_cx5_diesel_active = {
  folderName: '22_mazda_cx5_diesel_active',
  count: 24,
};

export const smallSlides22_mazda_cx5_diesel_active = getSlides(car_22_mazda_cx5_diesel_active, 'small');
export const largeSlides22_mazda_cx5_diesel_active = getSlides(car_22_mazda_cx5_diesel_active, 'large');

// 22_tucson
const car_22_tucson = {
  folderName: '22_tucson',
  count: 32,
};

export const smallSlides22_tucson = getSlides(car_22_tucson, 'small');
export const largeSlides22_tucson = getSlides(car_22_tucson, 'large');

// // card_model
// const car_card_model = {
//   folderName: 'card_model',
//   count: 27,
// };
//
// export const smallSlidescard_model = getSlides(car_card_model, 'small');
// export const largeSlidescard_model = getSlides(car_card_model, 'large');

// export const advancedSlides = [
//   { ...slides[0], title: 'Puppy in sunglasses', description: 'Mollie Sivaram' },
// ];
