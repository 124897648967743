import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import { useState } from 'react';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

export const CarAlbum = (props) => {
  const { title, smallImages, largeImages } = props;
  const [index, setIndex] = useState(-1);

  return (
    <div style={{ margin: 20, marginBottom: 100 }}>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h3>{title}</h3>
      </div>
      <PhotoAlbum layout="rows" photos={smallImages} targetRowHeight={150} onClick={({ index: current }) => setIndex(current)} />
      <Lightbox index={index} slides={largeImages} open={index >= 0} close={() => setIndex(-1)} plugins={[Fullscreen, Slideshow, Zoom]} />
    </div>
  );
};
