import React from 'react';

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Any Other Question? You Can contact us:</p>
              </div>
              <a id="email-link" href="mailto:info@mhautos.com.au?subject=Contact_mhautos&amp;body=Hi," className="btn btn-custom btn-lg">
                Send mail
              </a>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                <a
                  style={{ color: 'rgba(255, 255, 255, 0.75)' }}
                  href="https://www.google.com/maps/place/3411+Pacific+Hwy,+Slacks+Creek+QLD+4127/data=!4m2!3m1!1s0x6b914399972eafdf:0x3f998d98244ace3d?sa=X&ved=2ahUKEwjvr__y25CEAxWKwjgGHZMiAXkQ8gF6BAgZEAA"
                  target="_blank"
                >
                  3411 Pacific Hwy, Slacks Creek QLD 4127
                </a>
                {/*{props.data ? props.data.address : 'loading'}*/}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{' '}
                <a style={{ color: 'rgba(255, 255, 255, 0.75)' }} href={`tel:+${props.data ? props.data.phone : ''}`}>
                  {props.data ? props.data.phone : 'loading'}
                </a>
                <br />
                <a style={{ color: 'rgba(255, 255, 255, 0.75)' }} href={`tel:+${props.data ? props.data.phone2 : ''}`}>
                  {props.data ? props.data.phone2 : 'loading'}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{' '}
                {/*{props.data ? props.data.email : 'loading'}*/}
                <a style={{ color: 'rgba(255, 255, 255, 0.75)' }} href="mailto:yuefeng.cheng@mhautos.com.au">
                  yuefeng.cheng@mhautos.com.au
                </a>
                <br />
                {/*{props.data ? props.data.email2 : 'loading'}*/}
                <a style={{ color: 'rgba(255, 255, 255, 0.75)' }} href="mailto:info@mhautos.com.au">
                  info@mhautos.com.au
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 MEIHOU PTY LTD. Licence Number: 4679178</p>
        </div>
      </div>
    </div>
  );
};
